import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import EventBus from "../../controllers/EventBus";
import MyComponent from "../../components/custom/MyComponent";
import OrdemService from "../../services/OrdemService";
import Util from "../../utils/Util";
import ModalConfirmacao from "../../components/modal/ModalConfirmacao";
import InputDate from "../../components/forms/elements/InputDate";
import SelectRepresentanteByUsuario from "../../components/app/SelectRepresentanteByUsuario";
import Permissao from "../../controllers/Permissao";
import InputNumber from "../../components/forms/elements/InputNumber";
import FormFatura from "../Fatura/FormFatura";
import BtnButton from "../../components/forms/elements/BtnButton";
import SelectCategoriaProdutoServicoByUsuario from "../../components/app/SelectCategoriaProdutoServicoByUsuario";
import SelectTipoProdutoServicoByCategoria from "../../components/app/SelectTipoProdutoServicoByCategoria";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import Select from "../../components/forms/elements/Select";
import FormRelatorioOrdem001 from "../Relatorio/FormRelatorioOrdem001";
import { toast } from "react-toastify";
import SelectProprietarioByUsuario from "../../components/app/SelectProprietarioByUsuario";
import SelectOrdemTipoUsuario from "../../components/app/SelectOrdemTipoUsuario";
import DropDownMenuOrdemStatus from "../../components/app/DropDownMenuOrdemStatus";
import MostraValor from "../../components/app/MostraValor";
import DropDownMenuTag from "../../components/app/DropDownMenuTag";

class ListaOrdem extends MyComponent {

    constructor(props) {
        super(props);

        this.getInitState = this.getInitState.bind(this);
        this.handleChangeItemOrdemStatus = this.handleChangeItemOrdemStatus.bind(this);
        this.handleChangeAllOrdemStatus = this.handleChangeAllOrdemStatus.bind(this);
        this.handleChangeTags = this.handleChangeTags.bind(this);
        this.clear = this.clear.bind(this);
        this.handleClickMarcarLido = this.handleClickMarcarLido.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckOrdem = this.handleCheckOrdem.bind(this);
        this.handleCheckAllOrdem = this.handleCheckAllOrdem.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClickOrdemStatus = this.handleClickOrdemStatus.bind(this);
        this.handleClickMaisRegistros = this.handleClickMaisRegistros.bind(this);
        this.handleClickFaturar = this.handleClickFaturar.bind(this);
        this.handleImprimirLote = this.handleImprimirLote.bind(this);
        this.handleExcluirOrdem = this.handleExcluirOrdem.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
        this.podeFaturarItemSelecionados = this.podeFaturarItemSelecionados.bind(this);
        this.handleClickExpand = this.handleClickExpand.bind(this);
        this.getLista = this.getLista.bind(this);
        this.state = this.getInitState(props);
        this.inverval = null;

        this.cache = {
            active: true,
            id: "cache-lista-ordem",
            clear: true,
            state: {
                expand : true,
                model: {
                    busca: true,
                    idOrdem: true,
                    idOrdemTipo: true,
                    dtOrdemStart: true,
                    dtOrdemEnd: true,
                    idProprietario: true,
                    idRepresentante: true,
                    idOrdemStatus: true,
                    idOrdensStatus: true,
                    idTags: true,
                    idCategoriaProdutoServico: true,
                    idTipoProdutoServico: true,
                    flFiltroData: true,
                    flFiltroCdTipo: true,
                    flFiltroPropriedade: true,
                    pagina: true,
                    rowCount: true,
                    flPago: true,
                    flFiltroOrdem: true,
                    flFiltroTipoOrdem: true                    
                }
            }
        };
    }

    getInitState(props) {

        return {
            inverval: null,
            data: [],
            expand : true,
            model: {
                busca: "",
                idOrdem: "",
                idOrdemTipo: "",
                dtOrdemStart: "",
                dtOrdemEnd: "",
                idProprietario: "",
                idRepresentante: "",
                idOrdemStatus: "",
                idOrdensStatus: [1, 2, 3, 4, 5],
                idTags: [],
                idCategoriaProdutoServico: "",
                idTipoProdutoServico: "",
                flFiltroData: "O",
                flFiltroCdTipo: "T",
                flFiltroPropriedade: "T",
                pagina: 0,
                rowCount: 50,
                flPago: -1,
                flFiltroOrdem: "U",
                flFiltroTipoOrdem: "DESC"
                
            },
            ordemStatus: [
                { idOrdemStatus: 1, nome: 'Aguardando' },
                { idOrdemStatus: 2, nome: 'Concluído' },
                { idOrdemStatus: 3, nome: 'Cancelado' },
                { idOrdemStatus: 4, nome: 'Processando' },
                { idOrdemStatus: 5, nome: 'Faturado' },
            ],

            filtroData: [
                { value: 'U', label: 'Alteração' },
                { value: 'O', label: 'Ordem' },
            ],

            filtroTipo: [
                { value: "T", label: "Todas" },
                { value: "P", label: "Produto", labelStyle : "btn-outline-warning"},
                { value: "S", label: "Serviço" , labelStyle : "btn-outline-primary"},
                { value: "R", label: "Remap" , labelStyle : "btn-outline-danger"},
            ],

            filtroPropriedade: [
                { value: "T", label: "Todas" },
                { value: "M", label: "Criadas por mim" },
                { value: "C", label: "Onde fui Marcado" },
                { value: "P", label: "Onde comentei" },
            ],

            filtroRowCount: [
                { value: 50, label: "50 Registros" },
                { value: 100, label: "100 Registros" },
                { value: 200, label: "200 Registros" },
                { value: 500, label: "500 Registros" },
                { value: 1000, label: "1000 Registros" },
            ],

            filtroPagamento: [
                { value: -1, label: 'Todos' },
                { value: 1, label: 'Pagos' },
                { value: 0, label: 'Não Pagos' },
            ],

            filtroOrdem: [
                { value: 'O', label: 'Ordem' },
                { value: 'U', label: 'Alteração' },
            ],

            filtroTipoOrdem: [
                { value: 'ASC', label: 'Crescente' },
                { value: 'DESC', label: 'Decrescente' },
            ],

            filtroOrdemComentario: [
                { value: 'S', label: 'Sim' },
                { value: 'N', label: 'Não' },
            ],

            ordens: []
        };
    }

    componentDidMount() {
        super.componentDidMount(this.getLista);
        this.inverval = setInterval(() => {
            this.getLista();
        }, 1000 * 60 * 5);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        clearInterval(this.inverval);
    }

    clear() {
        this.setState(this.getInitState(), () => {
            this.getLista();
        });
    }

    getLista(merge = false) {
        if (merge) {
            OrdemService.list(this.state.model, (response) => {
                this.setState({ data: this.state.data.concat(response) });
                if (response.length === 0) {
                    toast.warning("Nenhum registro encontrado!");
                    this.setModel({ pagina: -1 });
                }
            });
        } else {
            this.setState(state => {
                state.data = [];
                state.model.pagina = 0;
                return state;
            }, () => {
                OrdemService.list(this.state.model, (response) => {
                    this.setState({ data: response, ordens: [] });
                });
            });
        }
    }

    handleClickExpand() {
        this.setState({ expand: !this.state.expand })
    }

    handleClickMaisRegistros() {
        if (this.state.model.pagina >= 0 && (this.state.data.length % this.state.model.rowCount) === 0) {
            this.setModel({ pagina: this.state.model.pagina + 1 }, () => { this.getLista(true) });
        }
    }

    handleClickFaturar(item) {

        if (this.state.ordens.length === 1) {
            this.props.navigate('/Faturar/' + this.state.ordens[0]);
            return;
        } else {
            this.props.navigate('/Faturar/', { state: { idOrdens: this.state.ordens } });
            return;
        }
    }

    handleClickVerFatura(item) {
        EventBus.dispatch("modal-add", {
            title: 'Fatura',
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                id: item.idFatura
            },
            form: FormFatura
        });
    }

    handleClickOrdemStatus(idOrdemStatus) {
        this.setModel({ idOrdemStatus: idOrdemStatus }, this.getLista);
    }

    onSubmit(event) {
        this.getLista();
    }

    getClassStatus(idOrdemStatus) {
        switch (idOrdemStatus) {
            case 1:
                return "text-bg-warning";
            case 2:
                return "text-bg-success";
            case 3:
                return "text-bg-danger";
            case 4:
                return "text-bg-primary";
            case 5:
                return "text-bg-dark";
            default:
                return "text-bg-light";
        }
    }

    getOrdemTags(item) {
        if(item.tags?.length > 0){
            var tags = item.tags.split(",");

            return tags.map((tag, index) => (
                <span className="badge text-bg-info fs-7 fw-normal m-1" key={index}> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tags-fill" viewBox="0 0 16 16">
                        <path d="M2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2zm3.5 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                        <path d="M1.293 7.793A1 1 0 0 1 1 7.086V2a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l.043-.043-7.457-7.457z"/>
                    </svg> {tag}
                </span>
            ));
        }

        return <></>;
        
    }

    getQtdeOrdemStatus(idOrdemStatus) {
        return this.state.data.reduce((soma, ordem) => parseInt(idOrdemStatus) === parseInt(ordem.idOrdemStatus) ? (soma + 1) : soma, 0);
    }

    getComentarioNaoLido() {
        return this.state.data.reduce((soma, ordem) => parseInt(ordem.flComentarioNaoLido) !== -1 ? (soma + 1) : soma, 0);
    }

    getTotalItem(prop) {
        return parseFloat(this.state.data.reduce((soma, item) => soma + (item[prop] || 0), 0)).toFixed(2);
    }

    handleClickMarcarLido() {
        EventBus.dispatch("modal-ordem-open", {
            config: {
                title: "Marcar mensagens como lida?",
                message: <>Deseja realmente marcar os comentários das ordems como lido?</>,
                btnConfirmText: "Sim, marcar como lido",
                description: "Esta é uma ação é irreversível!",
                style: "warning",
                confirm: () => {
                    var ordens = this.state.data.filter(item => parseInt(item.flComentarioNaoLido) !== -1).map(o => o.idOrdem);
                    OrdemService.markAllReadComentario(ordens, () => {
                        this.getLista();
                    });

                }
            },

        })
    }

    handleImprimirIndividual(idOrdem) {
        EventBus.dispatch("modal-add", {
            title: 'Impressão da Ordem',
            sizeModal: 'modal-lg',
            scrollable: false,
            config: {
                isModal: true,
                idOrdens: [idOrdem]
            },
            form: FormRelatorioOrdem001
        });
    }

    handleImprimirLote() {
        EventBus.dispatch("modal-add", {
            title: 'Impressão da Ordem',
            sizeModal: 'modal-lg',
            scrollable: false,
            config: {
                isModal: true,
                idOrdens: this.state.ordens
            },
            form: FormRelatorioOrdem001
        });
    }

    handleExcluirOrdem(item) {
        EventBus.dispatch("modal-confirmacao-open", {
            id: item.idBaixa,
            config: {
                title: "Excluir Ordem?",
                message: <>Deseja realmente excluir a ordem: <strong>{item.idOrdem} </strong>?</>,
                btnConfirmText: "Sim, excluir a ordem",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm: () => {
                    OrdemService.delete(item.idOrdem, () => {
                        this.getLista();
                    });
                }
            }
        })
    }

    handleCheckOrdem(event, item) {
        if (event.target.checked) {
            this.setState(state => {
                state.ordens.push(item.idOrdem);
                return state;
            });
        } else {
            this.setState(state => {
                state.ordens.splice(state.ordens.findIndex(idOrdem => parseInt(idOrdem) === parseInt(item.idOrdem)), 1);
                return state;
            });
        }
    }

    handleCheckAllOrdem(event) {
        if (event.target.checked) {
            this.setState(state => {
                state.ordens = [];
                state.data.forEach(item => {
                    state.ordens.push(item.idOrdem);
                });
                return state;
            });
        } else {
            this.setState(state => {
                state.ordens = [];
                return state;
            });
        }
    }

    handleChangeItemOrdemStatus(isChecked, item) {
        if (isChecked) {
            this.setState(state => {
                state.model.idOrdensStatus.push(item.value);
                return state;
            }, this.getLista);
        } else {
            this.setState(state => {
                var index = this.state.model.idOrdensStatus.findIndex(i => parseInt(i) === parseInt(item.value));
                state.model.idOrdensStatus.splice(index, 1);
                return state;
            }, this.getLista);
        }
    }

    handleChangeAllOrdemStatus(isChecked, itens) {        
        if (isChecked) {
            this.setState(state => {
                state.model.idOrdensStatus = itens.map((item) => item.value);
                return state;
            }, this.getLista);
        } else {
            this.setState(state => {
                state.model.idOrdensStatus = [];
                return state;
            }, this.getLista);
        }
    }

    handleChangeTags(isChecked, item) {
        if (isChecked) {
            this.setState(state => {
                state.model.idTags.push(item.value);
                return state;
            });
        } else {
            this.setState(state => {
                var index = this.state.model.idTags.findIndex(i => parseInt(i) === parseInt(item.value));
                state.model.idTags.splice(index, 1);
                return state;
            });
        }
    }

    podeFaturarItemSelecionados() {
        if (this.state.ordens.length > 0) {
            var ordem = this.state.data.find(o => parseInt(this.state.ordens[0]) === parseInt(o.idOrdem));
            if (ordem) {
                //var cdTipoProdutoServico = ordem.cdTipoProdutoServico;
                var idProprietario = ordem.idProprietario;
                var idOrdemTipo = ordem.idOrdemTipo;

                return this.state.ordens.length === (this.state.ordens.filter(idOrdem => {
                    var item = this.state.data.find(o => parseInt(idOrdem) === parseInt(o.idOrdem));
                    return (
                        parseInt(item.idOrdemStatus) === 2
                        && parseInt(item?.idProprietario) === parseInt(idProprietario) 
                        && parseInt(item?.idOrdemTipo) === parseInt(idOrdemTipo) 
                        //&& item.cdTipoProdutoServico === cdTipoProdutoServico;
                    )
                }).length);
            }
        }
        return false;
    }

    handleDoubleClick(e, item){
        this.props.navigate('/CadOrdem/' + item.idOrdem);
    }

    getRenderFilter() {
        return (
            <div className={`${this.state.expand ? '' : 'd-none'}`}>
                <div className="row g-2">
                    <InputNumber md={1} sizing="sm" label="Ordem" placeholder="9999" value={this.state.model.idOrdem} name="idOrdem" onChange={this.handleChange} min={1} opcionalLabel={false} />
                    <BtnGroup md={2} sizing="sm" className="w-100" name="flFiltroData" value={this.state.model.flFiltroData} label="Data da" options={this.state.filtroData} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    <InputDate md={2} sizing="sm" label="Data Inicial" value={this.state.model.dtOrdemStart} name="dtOrdemStart" onChange={(e) => { this.handleChange(e) }} opcionalLabel={false} />
                    <InputDate md={2} sizing="sm" label="Data Final" value={this.state.model.dtOrdemEnd} name="dtOrdemEnd" onChange={(e) => { this.handleChange(e) }} min={this.state.model.dtOrdemStart} opcionalLabel={false} />
                    
                    <DropDownMenuOrdemStatus label="Status da Ordem" sizing="sm" placeholder="Selecione o(s) status" className="btn-outline-primary text-nowrap" md={3} values={this.state.model.idOrdensStatus} name="idOrdensStatus" handleChangeItem={this.handleChangeItemOrdemStatus} handleChangeAll={this.handleChangeAllOrdemStatus} opcionalLabel={false} />
                    <DropDownMenuTag label="Tags" sizing="sm" placeholder="Selecione a(s) tags" className="btn-outline-primary text-nowrap" md={2} values={this.state.model.idTags} name="idTags" handleChangeItem={this.handleChangeTags} opcionalLabel={false} />

                    <SelectOrdemTipoUsuario sizing="sm" showBtnNewForm={false} md={4} label="Tipo da Ordem" placeholder="Selecione o tipo da Ordem" name="idOrdemTipo" value={this.state.model.idOrdemTipo} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    <SelectProprietarioByUsuario showBtnNewForm={false} sizing="sm" md={4} label="Proprietário" placeholder="Selecione o Proprietario" name="idProprietario" value={this.state.model.idProprietario} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    <SelectRepresentanteByUsuario showBtnNewForm={false} sizing="sm" md={4} label="Representante" placeholder="Selecione o Representante" name="idRepresentante" value={this.state.model.idRepresentante} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />

                    <BtnGroup md={5} sizing="sm" className="w-100" name="flFiltroPropriedade" value={this.state.model.flFiltroPropriedade} label="Propriedade" options={this.state.filtroPropriedade} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    <BtnGroup md={4} sizing="sm" className="w-100" name="flFiltroCdTipo" value={this.state.model.flFiltroCdTipo} label="Tipo da Ordem" options={this.state.filtroTipo} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    <BtnGroup md={3} sizing="sm" className="w-100" name="flPago" value={this.state.model.flPago} label="Status Pagamento" options={this.state.filtroPagamento} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />

                    <BtnGroup md={2} sizing="sm" className="w-100" name="flFiltroOrdem" value={this.state.model.flFiltroOrdem} label="Ordenar por data da:"  options={this.state.filtroOrdem} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    <BtnGroup md={2} sizing="sm" className="w-100" name="flFiltroTipoOrdem" value={this.state.model.flFiltroTipoOrdem} label="Tipo da Ordenação:"  options={this.state.filtroTipoOrdem} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />

                    <SelectCategoriaProdutoServicoByUsuario showBtnNewForm={false} sizing="sm" md={3} label="Categoria de Produto" placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    <SelectTipoProdutoServicoByCategoria showBtnNewForm={false} sizing="sm" md={3} idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} disabled={!this.state.model.idCategoriaProdutoServico} label="Tipo de Produto" placeholder="Selecione o Tipo do Produto" name="idTipoProdutoServico" value={this.state.model.idTipoProdutoServico} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    <Select label="Registros por Página" md={2} sizing="sm" value={this.state.model.rowCount} required={true} options={this.state.filtroRowCount} name="rowCount" onChange={(e) => { this.handleChange(e, this.onSubmit) }}></Select>
                </div>

                <div className="row g-2 pt-2">
                    <div className="col-md-12 mt-3">
                        <div className="row gap-2">
                            <div className="col">
                                <div className="col-md-12 input-group">
                                    <span className="input-group-text" id="addon-wrapping">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg>
                                    </span>
                                    <input type="text" className="form-control form-control-sm" autoFocus autoComplete="off" name="busca" placeholder="Digite a sua busca" value={this.state.model.busca} onChange={this.handleChange} />
                                    <button className="btn btn-outline-primary btn-sm" type="submit"> Buscar </button>
                                </div>
                            </div>
                            <div className="col-md-auto text-end d-grid d-md-block">
                                <button type="button" className="btn btn-outline-danger btn-sm" onClick={this.clear}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                        <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                    </svg> Limpar Busca
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getRenderHeader() {
        return (
            <tr>                
                <th className="d-none d-md-table-cell"></th>
                <th className="d-none d-md-table-cell">
                    <input type="checkbox" checked={this.state.ordens.length === this.state.data.length} className="form-check-input form-check-input-size-20" onChange={this.handleCheckAllOrdem} />
                </th>
                <th> ID </th>
                <th className="col-md d-none d-md-table-cell"> Tipo </th>
                <th className="col-md d-none d-md-table-cell"> Representante </th>
                <th className="col-md"> Cliente / Veículo </th>
                <th className="col-md text-center d-none d-md-table-cell"> Tags </th>
                <th className="text-center">
                    {this.getComentarioNaoLido() ?
                        <button className="btn btn-outline-primary btn-sm" type="button" onClick={this.handleClickMarcarLido}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-all" viewBox="0 0 16 16">
                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                            </svg>
                        </button>
                        :
                        <span className="text-success fw-bold">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-all" viewBox="0 0 16 16">
                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                            </svg>
                        </span>
                    }
                </th>
                <th className="col-md d-none d-md-table-cell text-end"> Valor </th>
                <th className="col-md text-center d-none d-md-table-cell"> Status </th>
                <th className="text-center d-none d-md-table-cell"> Pago </th>
                <th className="text-end">&nbsp;</th>
                <th className="d-none d-md-table-cell"></th>
            </tr>
        );
    }

    getRenderItem(item, index) {

        var bgColor = "";

        if(Boolean(item.flRemap)){
            bgColor="danger";
        }else if(item.cdTipoProdutoServico === "S"){
            bgColor="primary";
        }else if(item.cdTipoProdutoServico === "P"){
            bgColor="warning";
        }

        return (
            <tr onDoubleClick={e => this.handleDoubleClick(e, item)}>
                <td className="fs-9 text-end text-secondary align-middle d-none d-md-table-cell">
                    {index + 1}.
                </td>
                <td className="align-middle text-center text-nowrap d-none d-md-table-cell">
                    <input type="checkbox" className="form-check-input form-check-input-size-20" checked={this.state.ordens.findIndex(o => parseInt(o) === parseInt(item.idOrdem)) !== -1} onChange={(e) => { this.handleCheckOrdem(e, item) }} />
                </td>
                <td className="align-middle text-center text-nowrap">
                    <div><span className="rounded-pill badge w-100 fs-7 text-bg-secondary"> #{item.idOrdem} </span></div>
                    <div className="mt-1">
                        {item.cdTipoProdutoServico === "P" ?
                            <span className="rounded-pill badge w-100 fs-8 text-bg-warning">PRODUTO</span> :
                            <span className="rounded-pill badge w-100 fs-8 text-bg-primary">SERVIÇO</span>
                        }
                    </div>
                    
                    {Boolean(item.flRemap) ?
                        <div className="mt-1">                        
                            <span className="rounded-pill badge w-100 fs-8 text-bg-danger">REMAP</span>
                        </div> :<></> 
                    }
                    <div className="mt-1 d-block d-sm-none">
                        <span className={`rounded-pill badge w-100 fs-7 ${this.getClassStatus(item.idOrdemStatus)}`}> {item.nomeOrdemStatus} </span>
                    </div>
                </td>
                <td className="align-middle d-none d-md-table-cell">
                    {Util.date2Br(item.dataOrdem)} {item.horaOrdem} | {Util.date2Br(item.dataUpdate)} {item.horaUpdate}<br />
                    {item.nomeOrdemTipo}
                    <br /><i>{item.nomeCategoriaProdutoServico} - {item.nomeTipoProdutoServico}</i>
                    {item.idOrdemPai ? <><br /><span className="badge text-bg-primary"> Ordem Vinculada </span></> : <></>}
                </td>
                <td className="align-middle d-none d-md-table-cell">
                    <span className="fw-semibold">{item.nomeRepresentante}</span><br />
                    <span className="fst-italic">{item.nomeUsuario}</span>
                </td>
                <td className="align-middle">
                    <span className="text-uppercase fw-semibold">{item.ordemClienteNome}</span>
                    {item.flDadosVeiculo ? <><br />{item.ordemVeiculoMarcaNome} / {item.ordemVeiculoModelo} / {item.ordemVeiculoPlaca}</> : <></>}
                </td>
                <td className="align-middle text-center d-none d-md-table-cell">                    
                    {this.getOrdemTags(item)}
                </td>
                <td className="text-center align-middle">

                    {parseInt(item.flComentarioNaoLido) >= 0 ?
                        <button type="button" className="btn btn-sm btn-warning position-relative">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chat-dots" viewBox="0 0 16 16">
                                <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                            </svg>
                            {parseInt(item.flComentarioNaoLido) === 1 ?
                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success blink-me">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-at" viewBox="0 0 16 16">
                                        <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z" />
                                    </svg>
                                </span>
                                : <></>
                            }
                        </button>
                        : <></>
                    }
                </td>
                <td className="align-middle d-none d-md-table-cell text-end fw-bold">
                    <div className="text-primary">
                        {Permissao.getInstance().getPermissao(22) ?
                            <MostraValor>{Util.floatToReais(item.vlOrdem)}</MostraValor> :
                            <small className="text-danger"> OCULTO </small>
                        }
                    </div>
                    {item.vlPrecoRevenda > 0 ?
                        <div className="text-success">
                            {Permissao.getInstance().getPermissao(22) ?
                                <MostraValor>{Util.floatToReais(item.vlPrecoRevenda)}</MostraValor> :
                                <small className="text-danger"> OCULTO </small>
                            }
                        </div>
                    : <></>}
                </td>
                <td className="align-middle text-center d-none d-md-table-cell">
                    <span className={`rounded-pill badge w-100 fs-7 ${this.getClassStatus(item.idOrdemStatus)}`}> {item.nomeOrdemStatus} </span>                    
                </td>
                <td className="align-middle text-center d-none d-md-table-cell">
                    <span className={`rounded-pill badge w-100 fs-7 ${item.flPago ? 'text-bg-success' : 'text-bg-danger'}`}> {item.flPago ? 'Sim' : 'Não'} </span>
                </td>
                <td>
                    <Link to={`/CadOrdem/${item.idOrdem}`} className="btn btn-outline-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                        </svg>
                    </Link>
                    <div className="dropdown dropstart mt-1">
                        <button className="btn btn-outline-dark " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                            </svg>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <Link to={`/CadOrdem/${item.idOrdem}`} className="dropdown-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart" viewBox="0 0 16 16">
                                        <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                    </svg> Ver Ordem
                                </Link>
                            </li>
                            {Permissao.getInstance().getPermissao(30) ?
                                <li>
                                    <Link to={`/Faturar/${item.idOrdem}`} className={`dropdown-item ${item.flFaturado || parseInt(item.idOrdemStatus) !== 2 ? "disabled" : ""}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-plus" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5z" />
                                            <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                                            <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                                        </svg> Faturar Ordem
                                    </Link>
                                </li>
                                : <></>
                            }
                            {Permissao.getInstance().getPermissao(27) ?
                                <li>
                                    <button type="button" className={`dropdown-item ${!item.flFaturado ? "disabled" : ""}`} onClick={() => { this.handleClickVerFatura(item) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-coin" viewBox="0 0 16 16">
                                            <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                                        </svg> Ver Fatura
                                    </button>
                                </li>
                                : <></>
                            }
                            {Permissao.getInstance().getPermissao(51) ?
                                <li>
                                    <button type="button" className="dropdown-item" onClick={() => { this.handleImprimirIndividual(item.idOrdem) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                                        </svg> Imprimir Ordem
                                    </button>
                                </li>
                                : <></>
                            }
                            {Permissao.getInstance().getPermissao(89) ?
                                <li>
                                    <button type="button" className={`dropdown-item ${parseInt(item.idOrdemStatus) !== 3 ? "disabled" : ""}`} onClick={() => { this.handleExcluirOrdem(item) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                        </svg> Excluir Ordem
                                    </button>
                                </li>
                                : <></>
                            }
                        </ul>
                    </div>
                </td>
                <td className={`d-none d-md-table-cell border-start bg-${bgColor}`}>&nbsp;</td>
            </tr>
        );
    }

    render() {
        return (

            <div className="p-4 my-2 py-2 w-100">
                <div className="hstack gap-3 mb-1">
                    <h4 className="mb-3 w-100">Lista de Ordem(ns)</h4>
                    {Permissao.getInstance().getPermissao(30) ?
                        <BtnButton disabled={this.state.ordens.length === 0 || !this.podeFaturarItemSelecionados()} className="btn-outline-primary text-nowrap d-none d-sm-block" onClick={this.handleClickFaturar}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-plus" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5z" />
                                <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                                <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                            </svg> Faturar
                        </BtnButton> : <></>}

                    {Permissao.getInstance().getPermissao(51) ?
                        <BtnButton disabled={this.state.ordens.length === 0} className="btn-outline-danger text-nowrap d-none d-sm-block" onClick={this.handleImprimirLote}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                            </svg> Imprimir
                        </BtnButton> : <></>}
                    <Link to="/CadOrdem" className="btn btn-outline-primary text-nowrap">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                        </svg> Ordem
                    </Link>
                </div>

                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                    {this.getRenderFilter()}
                </form>

                <div className="table-responsive-sm mt-2">
                    <table className="table table-sm caption-top table-hover table-striped" style={{ fontSize: ".850em" }}>
                        <caption className="text-end">
                            <div className="hstack d-none d-lg-block">
                                <div className="hstack gap-2 w-100">
                                    {this.state.ordemStatus.map((item, index) => (
                                        <BtnButton key={index} className={`btn-sm rounded-pill py-0 fw-bold ${this.getClassStatus(item.idOrdemStatus)}`} onClick={(e) => { this.handleClickOrdemStatus(item.idOrdemStatus) }}>
                                            {this.getQtdeOrdemStatus(item.idOrdemStatus)} {item.nome}
                                        </BtnButton>
                                    ))}
                                    <div className="ms-auto">
                                        {this.state.data.length} Registro(s)
                                        <button type="button" className="btn btn-link ms-2" onClick={this.handleClickExpand}>
                                            {this.state.expand ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-funnel-fill" viewBox="0 0 16 16">
                                                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z" />
                                                </svg> :

                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-funnel" viewBox="0 0 16 16">
                                                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
                                                </svg>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </caption>
                        <thead>
                            {this.getRenderHeader()}
                        </thead>
                        <tbody>
                            {this.state.data.map((item, index) => (
                                <React.Fragment key={index}>
                                    {this.getRenderItem(item, index)}
                                </React.Fragment>
                            ))}

                        </tbody>
                        {Permissao.getInstance().getPermissao(22) ?
                            <tfoot>
                                <tr>
                                    <td className="d-none d-md-table-cell" colSpan={8}>&nbsp;</td>
                                    <td className="d-none d-md-table-cell align-middle text-end text-end text-primary fw-bold">
                                        <div className="text-primary">
                                            <MostraValor>
                                                {Util.floatToReais(this.getTotalItem("vlOrdem"))}
                                            </MostraValor>
                                        </div>
                                        <div className="text-success">
                                            <MostraValor>
                                                {Util.floatToReais(this.getTotalItem("vlPrecoRevenda"))}
                                            </MostraValor>
                                        </div>
                                    </td>
                                    <td className="d-none d-md-table-cell" colSpan={3}></td>
                                </tr>
                            </tfoot> : <></>
                        }
                    </table>
                </div>
                <div className="text-center pb-5">
                    {this.state.data.length > 0 ?
                        <div className="d-grid col-md-6 mx-auto">
                            <BtnButton className="btn-primary" onClick={this.handleClickMaisRegistros} disabled={this.state.model.pagina === -1 || (this.state.data.length % this.state.model.rowCount) !== 0}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                </svg> Carregar Mais Registros
                            </BtnButton>
                        </div>
                        : <></>
                    }
                </div>
                <ModalConfirmacao id="modal-ordem" />
            </div >
        )
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <ListaOrdem {...props} navigate={navigate} params={params} />
}

export default With